// extracted by mini-css-extract-plugin
export var container = "index-module--container--1TK4P";
export var image = "index-module--image--i2xlR";
export var welcome = "index-module--welcome--3xsUE";
export var h2 = "index-module--h2--22iO3";
export var h1 = "index-module--h1--1U03f";
export var h3 = "index-module--h3--1LE8z";
export var text = "index-module--text--1qnDP";
export var h4 = "index-module--h4--1LnIb";
export var p = "index-module--p---16tv";
export var spacer = "index-module--spacer--lHdbT";
export var inverted = "index-module--inverted--lGNTU";
export var img = "index-module--img--3aTOc";