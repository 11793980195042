import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "./index.module.css"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Inicio" />
      <div className={styles.container}>
        <GatsbyImage className={styles.image} image={getImage(data.allFile.edges[0].node)}></GatsbyImage>
        <div className={styles.welcome}>
          <p className={styles.h2}>Bienvenido al</p>
          <p className={styles.h1}>Colegio</p>
          <p className={styles.h1}>Charles Darwin</p>
        </div>
      </div>
      <p className={styles.h3}>¿Cómo será el regreso a clases?</p>
      <div className={`${styles.container} ${styles.spacer}`}>
        <GatsbyImage className={styles.image} imgClassName={styles.img} image={getImage(data.allFile.edges[1].node)}></GatsbyImage>
        <div className={styles.text}>
          <p className={styles.h4}>Áula Mixta</p>
          <p className={styles.p}>Muy pronto el regreso a clases podrá ser mixto. La asistencia a tu colegio podrá ser de manera alternada, para mantener las medidas de sanidad y distanciamiento social que exigen nuestras autoridades.</p>
          <p className={styles.p}>En tu colegio Charles Darwin todos nuestros alumnos tendrán la posibilidad de tomar clases todos los días, tus profesores harán sesiones mixtas para los alumnos presenciales y a distancia.</p>
        </div>
      </div>
      <div className={`${styles.container} ${styles.spacer} ${styles.inverted}`}>
        <div className={styles.text}>
          <p className={styles.h4}>Alumnos presenciales</p>
          <p className={styles.p}>Los alumnos  que asistan a las instalaciones del colegio trabajarán con sus profesores en el aula.</p>
        </div>
        <GatsbyImage className={styles.image} image={getImage(data.allFile.edges[2].node)}></GatsbyImage>
      </div>
      <div className={`${styles.container} ${styles.spacer}`}>
        <GatsbyImage className={styles.image} image={getImage(data.allFile.edges[3].node)}></GatsbyImage>
        <div className={styles.text}>
          <p className={styles.h4}>Alumnos a distancia</p>
          <p className={styles.p}>Podrás desde tu casa, trabajar en tiempo real, como si estuvieras físicamente en tu aula, a través de la plataforma Google Workspace, en la que podrás interactuar con tus profesores y compañeros en tiempo real. </p>
          <p className={styles.p}>También recuerda que tendrás un correo institucional para todas tus actividades.</p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage


export const query = graphql`
  query Images {
    allFile(
      filter: { sourceInstanceName: { eq: "inicio" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
  }
`
